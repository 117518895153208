import { v4 } from "uuid";

// Helper function to generate a new row with default values
const generateNewRow = () => ({
  id: v4(),
  description: "",
  type: "percent",
  value: 0,
  allProducts: false,
  // We use this when updating a row to tell us this service charge needs to be created
  createdRow: true,
});

export default generateNewRow;
