import {
  hasNumberValidationError,
  validateRequiredField,
} from "../../../components/Datagrid/utils/validation";

const getColumns = ({
  POSPaymentTypeOptions = [],
  bookingTypeOptions = [],
}) => [
  {
    field: "name",
    headerName: "Name",
    type: "text",
    editable: true,
    minWidth: 200,
    preProcessEditCellProps: validateRequiredField,
  },
  {
    field: "code",
    headerName: "Code",
    type: "text",
    editable: true,
    minWidth: 120,
    preProcessEditCellProps: validateRequiredField,
  },
  {
    field: "type",
    headerName: "Type",
    type: "singleSelect",
    editable: true,
    valueOptions: [
      { text: "Polaris", value: "POLARIS" },
      { text: "Client", value: "CLIENT" },
      { text: "Integration", value: "INTEGRATION" },
    ],
    minWidth: 120,
    preProcessEditCellProps: validateRequiredField,
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
  },
  {
    field: "POSPaymentTypeId",
    headerName: "POS Payment Type",
    type: "singleSelect",
    editable: true,
    valueOptions: POSPaymentTypeOptions,
    minWidth: 200,
    preProcessEditCellProps: validateRequiredField,
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
  },
  {
    field: "defaultBookingTypeId",
    headerName: "Default Booking Type",
    type: "singleSelect",
    editable: true,
    valueOptions: bookingTypeOptions,
    minWidth: 200,
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
  },
  {
    field: "defaultDiscountCode",
    headerName: "Default Discount Code",
    type: "text",
    editable: true,
    minWidth: 200,
  },
  {
    field: "orderMessageTimeoutMinutes",
    headerName: "Order Message Timeout (Minutes)",
    type: "number",
    editable: true,
    minWidth: 275,
    preProcessEditCellProps: params => {
      const {
        props,
        props: { value },
      } = params;

      // If value is empty, it's valid (not required)
      if (!value) {
        return props;
      }

      // Use the hasNumberValidationError function to validate the number
      const hasError = hasNumberValidationError(value, true);

      // Additional check for positive integer
      const isNegative = Number(value) < 0;

      return {
        ...props,
        error: hasError || isNegative,
      };
    },
  },
];

export default getColumns;
