import React from "react";

import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";
import {
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";

import "react-perfect-scrollbar/dist/css/styles.css";

import { withStyles } from "@mui/styles";
import CssBaseline from "@mui/material/CssBaseline";
import CircularProgress from "@mui/material/CircularProgress";
import { Helmet } from "react-helmet";

import { closeAction } from "../helpers/snackbar/utils";

import BlockUi from "../components/BlockUi";
import Login from "./Login";
import ChangePassword from "./Users/ChangePassword";

import TopBar from "../components/TopBar";
import Sidebar from "../components/Sidebar";
import ErrorPage from "./ErrorPage";
import NotFound from "./NotFound";
import Dashboard from "./Dashboard";
import BookingsList from "./Bookings/List";
import BookingAdd from "./Bookings/Add";
import BookingEdit from "./Bookings/Edit";

import AuditLogDashboard from "./AuditLogs/Dashboard";

import BookingTypesList from "./BookingTypes/List";
import BookingTypeAddEdit from "./BookingTypes/AddEdit";

import DepartmentsList from "./Departments/List";
import ServerSetup from "./ServerSetup/ServerSetup.js";

import SitesList from "./Sites/List";
import SiteAddEdit from "./Sites/AddEdit";

import OrdersList from "./Orders/List";

import PDQPaymentTypesList from "./PDQPaymentTypes/List";

import PriceLevelSetups from "./PriceLevelSetups/List";

import Products from "./Products/List";
import ProductsAddEdit from "./Products/AddEdit";
import ProductsBulkManagement from "./ProductsBulkManagement";

import SubGroupsList from "./ProductTags/SubGroupsList";

import Tags from "./ProductTags/List";
import TagsAddEdit from "./ProductTags/AddEdit";

import Tills from "./Tills/List";
import TillConfig from "./Tills/Config";

import VoidRefundReasonsList from "./VoidRefundReasons/List";

import ProductGroupsList from "./ProductTags/ProductGroupsList";

import ProductOptions from "./ProductOptions/List";
import ProductOptionGroups from "./ProductOptionGroups/List";
import ProductOptionGroupsEdit from "./ProductOptionGroups/AddEdit";

import ModifierGroupList from "./ModifierGroups/List";
import ModifierGroupsEdit from "./ModifierGroups/AddEdit";

import UserList from "./Users/List";
import UserAddEdit from "./Users/AddEdit";

import OrganisationList from "./Organisations/List";

import ChangesetList from "./Changesets/List";
import PastChangesetList from "./Changesets/ListPast";
import ChangesetAddEdit from "./Changesets/AddEdit";

import PromotionsList from "./Promotions/List";
import PromotionAddEdit from "./Promotions/AddEdit";
import PromotionViewChange from "./Promotions/ViewChange";

import PaymentTypesList from "./PaymentType/List";
import PaymentTypeAddEdit from "./PaymentType/AddEdit";

import PrintTextsList from "./PrintTexts/List.js";

import SalesAreasList from "./SalesAreas/List";
import ShiftsList from "./Shifts/List";

// Reports imports
import AddSubscriber from "./Reports/AddSubscriber";
import EditReport from "./Reports/EditReport";
import GenerateReport from "./Reports/GenerateReport";
import ReportList from "./Reports/List";
import ManageSubscribers from "./Reports/ManageSubscribers";

import ServiceChargesList from "./ServiceCharges/List";
import Settings from "./Settings/Settings";
import PromotionsDebug from "./Promotions/PromotionsDebug";
import ExpiredLoginModal from "../components/ExpiredLoginModal";
import UserTimeoutModal from "../components/UserTimeoutModal";

import ClassComponentParamWrapper from "../components/ClassComponentParamWrapper";

const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
  },
  logo: {
    padding: 10,
  },
  appFrame: {
    position: "relative",
    display: "flex",
    width: "100%",
    height: "100%",
  },
  content: {
    width: "100%",
    padding: theme.spacing(2),
    marginTop: theme.mixins.toolbar.minHeight,
  },
  paper: {
    width: "calc(100% - 10px)",
    padding: 10,
    marginTop: theme.spacing(1),
    overflowX: "auto",
  },
});

const MainApp = withStyles(styles, { withTheme: true })(
  inject("appStore")(
    observer(({ appStore, classes }) => {
      const mainAppRoutes = [
        {
          element: <Dashboard />,
          visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
          url: "",
        },
        {
          element: <AuditLogDashboard />,
          visibleTo: ["SUPER"],
          url: "/auditLogs",
        },
        {
          element: <BookingsList />,
          visibleTo: ["SUPER"],
          url: "/bookings",
        },
        {
          element: <BookingAdd />,
          visibleTo: ["SUPER"],
          url: "/bookings/add",
        },
        {
          element: <BookingEdit />,
          visibleTo: ["SUPER"],
          url: "/bookings/:id",
        },
        {
          element: <BookingTypesList />,
          visibleTo: ["SUPER"],
          url: "/bookingtypes",
        },
        {
          element: <BookingTypeAddEdit />,
          visibleTo: ["SUPER"],
          url: "/bookingtypes/add",
        },
        {
          element: <BookingTypeAddEdit />,
          visibleTo: ["SUPER"],
          url: "/bookingtypes/:bookingTypeId",
        },
        {
          element: <DepartmentsList />,
          visibleTo: ["SUPER"],
          url: "/departments",
        },
        {
          element: <ServerSetup />,
          visibleTo: ["SUPER"],
          url: "/server-setup",
        },
        {
          element: <SitesList />,
          visibleTo: ["SUPER"],
          url: "/sites",
        },
        {
          element: <SiteAddEdit />,
          visibleTo: ["SUPER"],
          url: "/sites/add",
        },
        {
          element: <SiteAddEdit />,
          visibleTo: ["SUPER"],
          url: "/sites/:siteId",
        },
        {
          element: <Tills />,
          visibleTo: ["SUPER"],
          url: "/tills",
        },
        {
          element: <TillConfig />,
          visibleTo: ["SUPER"],
          url: "/tills/:tillId",
        },
        {
          element: <VoidRefundReasonsList />,
          visibleTo: ["SUPER"],
          url: "/void-refund-reasons",
        },
        {
          element: <OrdersList />,
          visibleTo: ["SUPER"],
          url: "/orders",
        },
        {
          element: <PDQPaymentTypesList />,
          visibleTo: ["SUPER"],
          url: "/pdq-payment-types",
        },
        {
          element: <PriceLevelSetups />,
          visibleTo: ["SUPER"],
          url: "/price-level-setups",
        },
        {
          element: <Products />,
          visibleTo: ["SUPER"],
          url: "/products",
        },
        {
          element: <ProductsAddEdit />,
          visibleTo: ["SUPER"],
          url: "/products/add",
        },
        {
          element: <ProductsAddEdit />,
          visibleTo: ["SUPER"],
          url: "/products/:productId",
        },
        {
          element: <ProductsAddEdit />,
          visibleTo: ["SUPER"],
          url: "/products/:productId/sites/:siteId",
        },
        {
          element: <ProductsBulkManagement />,
          visibleTo: ["SUPER"],
          url: "/products/bulk",
        },
        {
          element: <ProductsBulkManagement />,
          visibleTo: ["SUPER"],
          url: "/products/bulk/:siteId",
        },
        {
          element: <ProductGroupsList />,
          visibleTo: ["SUPER"],
          url: "/product-groups",
        },
        {
          element: <PromotionsList />,
          visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
          url: "/promotions",
        },
        {
          element: <PromotionAddEdit />,
          visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
          url: "/promotions/add",
        },
        {
          element: <PromotionAddEdit />,
          visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
          url: "/promotions/:promotionId",
        },
        {
          element: <PromotionViewChange />,
          visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
          url: "/promotions/:promotionId/change/:changeId",
        },
        {
          element: <PromotionsDebug />,
          visibleTo: ["SUPER"],
          url: "/test/promotions",
        },
        {
          element: <SubGroupsList />,
          visibleTo: ["SUPER"],
          url: "/sub-groups",
        },
        {
          element: <Tags />,
          visibleTo: ["SUPER"],
          url: "/product-tags",
        },
        {
          element: <TagsAddEdit />,
          visibleTo: ["SUPER"],
          url: "/product-tags/add",
        },
        {
          element: <TagsAddEdit />,
          visibleTo: ["SUPER"],
          url: "/product-tags/:productTagId",
        },
        {
          element: <ProductOptions />,
          visibleTo: ["SUPER"],
          url: "/product-options",
        },
        {
          element: <ProductOptionGroups />,
          visibleTo: ["SUPER"],
          url: "/product-option-groups",
        },
        {
          element: <ProductOptionGroupsEdit />,
          visibleTo: ["SUPER"],
          url: "/product-option-groups/add",
        },
        {
          element: <ProductOptionGroupsEdit />,
          visibleTo: ["SUPER"],
          url: "/product-option-groups/:productOptionGroupId",
        },
        // TODO: Other routes should be reworked to be formatted like this
        {
          element: <ModifierGroupList />,
          visibleTo: ["SUPER"],
          url: "/modifier-groups",
          children: [
            {
              element: <ModifierGroupsEdit />,
              visibleTo: ["SUPER"],
              url: ":modifierGroupId",
            },
            {
              element: <ModifierGroupsEdit />,
              visibleTo: ["SUPER"],
              url: "add",
            },
          ],
        },
        {
          element: <UserList />,
          visibleTo: ["SUPER"],
          url: "/users",
        },
        {
          element: <ClassComponentParamWrapper Component={UserAddEdit} />,
          visibleTo: ["SUPER"],
          url: "/users/add",
        },
        {
          element: <ChangePassword />,
          visibleTo: ["SUPER"],
          url: "/users/change-password",
        },
        {
          element: <ClassComponentParamWrapper Component={UserAddEdit} />,
          visibleTo: ["SUPER"],
          url: "/users/:userId",
        },
        {
          element: <OrganisationList />,
          visibleTo: ["SUPER"],
          url: "/organisations",
        },
        {
          element: <ChangesetList />,
          visibleTo: ["SUPER"],
          url: "/changesets",
        },
        {
          element: <PastChangesetList />,
          visibleTo: ["SUPER"],
          url: "/past-changesets",
        },
        {
          element: <ChangesetAddEdit />,
          visibleTo: ["SUPER"],
          url: "/changesets/add",
        },
        {
          element: <PaymentTypesList />,
          visibleTo: ["SUPER"],
          url: "/payment-types",
        },
        {
          element: <PaymentTypeAddEdit />,
          visibleTo: ["SUPER"],
          url: "/payment-types/add",
        },
        {
          element: <PaymentTypeAddEdit />,
          visibleTo: ["SUPER"],
          url: "/payment-types/:paymentTypeId",
        },
        {
          element: <PrintTextsList />,
          visibleTo: ["SUPER"],
          url: "/print-texts",
        },
        {
          element: <SalesAreasList />,
          visibleTo: ["SUPER"],
          url: "/sales-areas",
        },
        {
          element: <ServiceChargesList />,
          visibleTo: ["SUPER"],
          url: "/service-charges",
        },
        {
          element: <ShiftsList />,
          visibleTo: ["SUPER"],
          url: "/shifts",
        },
        {
          element: <ReportList />,
          visibleTo: ["SUPER"],
          url: "/reports",
        },
        {
          element: <ManageSubscribers />,
          visibleTo: ["SUPER"],
          url: "/reports/manage-subscribers",
        },
        {
          element: <AddSubscriber />,
          visibleTo: ["SUPER"],
          url: "/reports/manage-subscribers/add",
        },
        {
          element: <EditReport />,
          visibleTo: ["SUPER"],
          url: "/reports/:reportId",
        },
        {
          element: <GenerateReport />,
          visibleTo: ["SUPER"],
          url: "/reports/:reportId/generate",
        },
        {
          element: <Settings />,
          visibleTo: ["SUPER"],
          url: "/settings",
        },
        {
          element: <ErrorPage />,
          visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
          url: "/error",
        },
        {
          element: <NotFound />,
          visibleTo: ["PROMOTIONS_ADMIN", "SUPER"],
          url: "*",
        },
      ];

      const generateRoutes = () => {
        const routes = !appStore.session?.scopes
          ? mainAppRoutes
          : mainAppRoutes.filter(({ visibleTo }) =>
              visibleTo.find(
                permissionToFind =>
                  permissionToFind === appStore.session.scopes,
              ),
            );

        return routes.map(({ url, element, children }) => {
          if (children?.length) {
            const childRoutes = !appStore.session?.scopes
              ? children
              : children.filter(({ visibleTo }) =>
                  visibleTo.find(
                    permissionToFind =>
                      permissionToFind === appStore.session.scopes,
                  ),
                );

            return (
              <Route path={url} key={url.replace(/\//g, "")}>
                <Route index={true} element={element} />
                {childRoutes.map(({ url: childUrl, element: childElement }) => (
                  <Route
                    path={childUrl}
                    element={childElement}
                    key={childUrl.replace(/\//g, "")}
                  />
                ))}
              </Route>
            );
          }

          return (
            <Route path={url} element={element} key={url.replace(/\//g, "")} />
          );
        });
      };

      return (
        <BlockUi
          classes={classes.root}
          blocking={appStore.isLoading}
          renderChildren={!appStore.isInitializing}
        >
          <div className={classes.appFrame}>
            <SnackbarProvider
              action={closeAction}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              maxSnack={3}
            >
              <TopBar />
              <Sidebar />
              <ExpiredLoginModal />
              <UserTimeoutModal />
              {appStore?.viewer?.isPasswordChangeRequired && (
                <Navigate to="/app/users/change-password" />
              )}
              <main className={classes.content} data-cy="main-content">
                <Routes>{generateRoutes()}</Routes>
              </main>
            </SnackbarProvider>
          </div>
        </BlockUi>
      );
    }),
  ),
);

const AppComponent = ({ appStore: app }) => {
  const handleAppRouteRender = () => {
    let toReturn = <MainApp />;

    if (app.viewer === null && app?.isInitializing === false) {
      toReturn = <Navigate to="/login" />;
    }

    return toReturn;
  };

  const initialisationLoader = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Helmet title="Loading" />
      <CircularProgress />
    </div>
  );

  const router = createBrowserRouter([
    {
      path: "/login",
      element:
        app.viewer === null ? (
          app.isInitializing ? (
            initialisationLoader
          ) : (
            <Login />
          )
        ) : (
          <Navigate to={"/app"} />
        ),
    },
    { path: "/app/*", element: handleAppRouteRender() },
    {
      path: "*",
      element:
        app.viewer !== null ? (
          <Navigate to="/app/404" />
        ) : (
          <Navigate to="/login" />
        ),
    },
  ]);

  return (
    <>
      <Helmet
        defaultTitle="Polaris Elements"
        titleTemplate="%s | Polaris Elements"
      />
      <CssBaseline />
      <RouterProvider router={router} />
    </>
  );
};

AppComponent.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

const App = withStyles(styles, { withTheme: true })(
  inject("appStore")(observer(AppComponent)),
);

export default App;
