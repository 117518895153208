import React from "react";
import PageWithHeading from "../../components/PageWithHeading";
import OrganisationsBulkEditor from "./components/OrganisationsBulkEditor";

const OrganisationsList = () => (
  <PageWithHeading title="Organisations">
    <OrganisationsBulkEditor />
  </PageWithHeading>
);

export default OrganisationsList;
