import "core-js";
import React from "react";
import { createRoot } from "react-dom/client";
import { configure } from "mobx";
import { Provider } from "mobx-react";

import { createHttpLink } from "@apollo/client/link/http";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
  defaultDataIdFromObject,
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { find } from "lodash";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { LicenseInfo } from "@mui/x-license";

import Observable from "zen-observable";

import {
  StyledEngineProvider,
  createTheme,
  ThemeProvider,
} from "@mui/material/styles";

import GlobalStyles from "./components/GlobalStyles";

import App from "./containers/App";
import AppStore from "./stores/AppStore";
import reportWebVitals from "./reportWebVitals";
import { unregister } from "./serviceWorkerRegistration";
import logoutActivityMonitor, {
  setSessionExpiryTime,
} from "./logoutActivityMonitor";

unregister();

configure({
  enforceActions: "observed",
});

LicenseInfo.setLicenseKey(
  "0b87fbd7accfe3d118d08f1a0cf14166Tz05OTMzMSxFPTE3NTk1ODI3MTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==",
);

const possiblyUnhandledRejections = new Map();

if (window) {
  // when a rejection is unhandled, add it to the map
  window.onunhandledrejection = event => {
    possiblyUnhandledRejections.set(event.promise, event.reason);
  };

  window.onrejectionhandled = event => {
    possiblyUnhandledRejections.delete(event.promise);
  };

  setInterval(() => {
    possiblyUnhandledRejections.forEach((reason, promise) => {
      // do something to handle these rejections
      // eslint-disable-next-line no-console
      console.debug(promise, reason);
    });
    possiblyUnhandledRejections.clear();
  }, 30000);
}

// Create apollo link that updates our lastQuery variable
const storeLatestRequestTime = (operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext();

    const {
      response: { headers },
    } = context;

    if (headers) {
      setSessionExpiryTime(headers.get("session-expires-at"));
    }

    return response;
  });
};

// @todo use config for local
const httpLink = createHttpLink({
  uri: !window.location.href.includes("polaris-cloud.app")
    ? `${window.location.protocol}//${window.location.hostname}:3000/graphql`
    : `${window.location.protocol}//gql.${window.location.hostname}`, // TODO ensure port
});

const SESSION_TOKEN_NAME = "token";
let appStore = {};

const authLink = setContext((request, { headers = {} }) => {
  const token = localStorage.getItem(SESSION_TOKEN_NAME);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      "x-app": "d0090237", // @todo drive from config?
    },
  };
});

// "consistent-return" below is disabled due to how "onError" functions: it requires that something is returned
// only if it is going to retry the operation (see https://www.apollographql.com/docs/react/api/link/apollo-link-error/)

const logError = onError(error => {
  if (
    find(error.graphQLErrors, {
      message: "This operation requires authentication",
    })
  ) {
    if (appStore.viewer !== null) {
      appStore.setLoading();
      appStore.setExpiredLogin();
      return new Observable(async observer => {
        while (appStore.expiredLogin) {
          await new Promise(resolve => {
            setTimeout(() => {
              resolve();
            }, 1000);
          });
        }

        error.forward(error.operation).subscribe({
          next: result => {
            observer.next(result);
          },
          complete: () => observer.complete.bind(observer)(),
        });
      });
    }
  }

  const scopeError = find(error.graphQLErrors, gqlError =>
    /User does not have required scope/.test(gqlError.message),
  );

  if (scopeError) {
    window.location.href = "/app/error?error=scopeError";
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    dataIdFromObject(responseObject) {
      // If you're getting warnings about not being able to generate a cache id
      // then create one here. This is relevant for settings and may be relevant for types
      // on the API that don't have an ID
      // https://www.apollographql.com/docs/react/caching/cache-configuration#calculating-an-objects-cache-id
      switch (responseObject.__typename) {
        case "Settings":
          return "Settings:1";
        case "JustEatCredentials":
          return "JustEatCredentials:1";
        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
    typePolicies: {
      Query: {
        fields: {
          changesetsWithCount: {
            // Tell Apollo to ignored arguments and treat all queries as the same
            // so we can cache any combo of offset, limit and sort as a single cache item.
            keyArgs: ["filter"],
            // // Concatenate the incoming list items with

            // // the existing list items.
            read(existing, { args: { offset, limit } }) {
              const requestedChangesets =
                existing &&
                Object.entries(existing.changesets || {})
                  .map(([key, value]) => {
                    if (key >= offset && key < offset + limit) {
                      return value;
                    }
                    return null;
                  })
                  .filter(value => value !== null);

              if (!requestedChangesets?.length) {
                // There are no matching entries in the cache, so return
                // undefined to say its not here
                return undefined;
              }

              // Return the cached items between the offset and limit
              // given that we have them stored in one big object
              return (
                existing && {
                  ...existing,
                  changesets: requestedChangesets,
                }
              );
            },
            merge(
              // I don't have a choice but to disable this, it's not our function

              existing = { changesets: {} },
              incoming,
              { args: { offset = 0 } },
            ) {
              // When we get a query response we'll merge it with the existing
              // cache item for this query.
              const merged = { ...existing };

              // Adding them with numeric keys according to the offset and limit
              // effectively means we could from page 1 to 4 on a table and Apollo
              // would be able to handle that and not just assume page numbers 2 & 3 don't exist
              for (let i = 0; i < incoming.changesets.length; i += 1) {
                merged.changesets = {
                  ...existing.changesets,
                  ...merged.changesets,
                  [offset + i]: incoming.changesets[i],
                };
              }

              // Copy the totalRows value
              merged.totalRows = incoming.totalRows;
              // This might not be required but I lost it in the merge somewhere, or it wasn't there at the start
              // and I think Apollo requires it

              merged.__typename = "ChangesetsResult";
              return merged;
            },
          },
        },
      },
    },
  }),
  link: ApolloLink.from([logError, authLink, storeLatestRequestTime, httpLink]),
});

appStore = AppStore.create(
  {},
  {
    SESSION_TOKEN_NAME,
    apolloClient: client,
    logger: console,
  },
);

logoutActivityMonitor(
  appStore.settingsStore.loadNoCache,
  appStore.setSessionExpiryCountdown,
);

// If we're testing then expose the appStore object so that Cypress
// Can authenticate without having to use the UI
if (window.Cypress) {
  window.appStore = appStore;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#1a3265",
      light: "rgba(26,50,101,0.7)",
      dark: "rgba(26,50,101,0.7)",
      grey: "#f5f5f5",
    },
    secondary: {
      main: "#00addd",
      contrastText: "#ffffff",
      dark: "rgba(0,173,221,0.7)",
    },
    text: {
      primary: "#082130",
      secondary: "#00addd",
      disabled: "rgba(119,119,119,0.5)",
      hint: "rgba(255,255,255,0.7)",
    },
    info: {
      main: "#2196f3",
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ["DM Sans", "Ariel", "sans-serif"],
    h1: {
      fontSize: 35,
    },
    h2: {
      fontSize: 30,
    },
    h3: {
      fontSize: 25,
      fontWeight: 400,
    },
    h4: {
      fontSize: 22,
    },
    h5: {
      fontSize: 20,
    },
    h6: {
      fontSize: 18,
      fontWeight: 400,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
    },
    body2: {
      fontWeight: 400,
    },
    button: {
      fontWeight: 400,
    },
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          color: "red",
        },
      },
    },
  },
  shadows: [
    "none",
    "0 0 0 1px rgba(63,63,68,0.05), 0 1px 2px 0 rgba(63,63,68,0.15)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 2px 2px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 3px 4px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 6px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 5px 8px -2px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 12px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 12px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 6px 16px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 7px 16px -4px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 8px 18px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 9px 18px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 10px 20px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 11px 20px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 12px 22px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 13px 22px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 14px 24px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 16px 28px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 18px 30px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 20px 32px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 22px 34px -8px rgba(0,0,0,0.25)",
    "0 0 1px 0 rgba(0,0,0,0.31), 0 24px 36px -8px rgba(0,0,0,0.25)",
  ],
});

const root = createRoot(document.getElementById("root"));

root.render(
  <ApolloProvider client={client}>
    <Provider appStore={appStore}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <App />
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </ApolloProvider>,
);

reportWebVitals();
