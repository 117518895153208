import React, { useState } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { DataGrid } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";
import {
  ADD_ORGANISATION,
  GET_ORGANISATIONS,
  UPDATE_ORGANISATION,
  GET_PAYMENT_TYPES,
  GET_BOOKING_TYPES,
} from "../../../helpers/apollo/utils";
import getColumns from "../utils/columns";
import generateNewRow from "../utils/generateNewRow";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const OrganisationsBulkEditor = ({ appStore }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);
  const [POSPaymentTypeOptions, setPOSPaymentTypeOptions] = useState([]);
  const [bookingTypeOptions, setBookingTypeOptions] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-organisations-error-snackbar",
      },
    });
  };

  // Fetch POS payment types
  const { loading: loadingPaymentTypes } = useQuery(gql(GET_PAYMENT_TYPES()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const paymentTypes = data?.paymentTypes || [];
      const options = paymentTypes.map(type => ({
        text: type.name,
        value: type.id,
      }));
      setPOSPaymentTypeOptions(options);
    },
    onError: error => {
      errorSnackbar(error.message);
    },
  });

  // Fetch booking types
  const { loading: loadingBookingTypes } = useQuery(gql(GET_BOOKING_TYPES()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const bookingTypes = data?.bookingTypes || [];
      const options = bookingTypes.map(type => ({
        text: type.name,
        value: type.id,
      }));
      setBookingTypeOptions(options);
    },
    onError: error => {
      errorSnackbar(error.message);
    },
  });

  const { loading: loadingOrganisations } = useQuery(gql(GET_ORGANISATIONS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const organisations = data?.organisations;

      if (organisations) {
        // Map the nested objects to flat fields
        const mappedOrganisations = organisations.map(org => ({
          ...org,
          POSPaymentTypeId: org.POSPaymentType?.id || "",
          defaultBookingTypeId: org.defaultBookingType?.id || "",
        }));
        setTableData(mappedOrganisations);
      }

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
  });

  const [addOrganisation] = useMutation(gql(ADD_ORGANISATION()), {
    onCompleted: () => {
      enqueueSnackbar("Your new organisation has been created", {
        SnackbarProps: {
          "data-testid": "bulk-organisations-added-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_ORGANISATIONS()), "Organisations"],
  });

  const [updateOrganisation] = useMutation(gql(UPDATE_ORGANISATION()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "bulk-organisations-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_ORGANISATIONS()), "Organisations"],
  });

  const addRow = () => {
    const newTableData = [...tableData];
    newTableData.unshift(generateNewRow());
    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);
    setTableData(newTableData);
    setCreatedRow(false);
  };

  const handleSubmitRow = async (updatedRow, originalRow) => {
    const managableFields = [
      "name",
      "code",
      "type",
      "POSPaymentTypeId",
      "defaultBookingTypeId",
      "defaultDiscountCode",
      "orderMessageTimeoutMinutes",
    ];

    const rowChanges = managableFields.reduce((changes, field) => {
      if (updatedRow.createdRow || originalRow[field] !== updatedRow[field]) {
        changes[field] = updatedRow[field];
      }
      return changes;
    }, {});

    const submitData = {
      variables: {
        input: rowChanges,
      },
    };

    if (Object.keys(rowChanges).length) {
      if (updatedRow.createdRow !== undefined) {
        return addOrganisation(submitData).then(() => {
          setCreatedRow(false);
          return updatedRow;
        });
      } else {
        submitData.variables.input.id = updatedRow.id;
        return updateOrganisation(submitData).then(() => {
          return updatedRow;
        });
      }
    } else {
      return updatedRow;
    }
  };

  // Get columns with the options
  const columns = getColumns({
    POSPaymentTypeOptions,
    bookingTypeOptions,
  });

  return (
    <StyledBox>
      <DataGrid
        columns={columns}
        editMode="row"
        loading={
          loadingOrganisations || loadingPaymentTypes || loadingBookingTypes
        }
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={handleSubmitRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

OrganisationsBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(OrganisationsBulkEditor));
