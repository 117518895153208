export const hasNumberValidationError = (value, onlyInteger) => {
  const checkForNonFloatValue = /^(?:[1-9]\d*|0)?(?:\.\d+)?$/;
  const checkForNonIntegerValue = /^[0-9]*$/;

  const hasError = !(
    onlyInteger ? checkForNonIntegerValue : checkForNonFloatValue
  ).test(value || "");

  // If value is empty in API, it's returned as null, so we ignore this from validation
  return value === null ? false : hasError;
};

export const hasCharacterLengthValidationError = ({
  value,
  minLength = null,
  maxLength = null,
}) =>
  (minLength !== null && value.length < minLength) ||
  (maxLength !== null && value.length > maxLength);

export const validateRequiredField = ({ props }) => ({
  ...props,
  error: !props.value,
});
