import { hasNumberValidationError } from "../../../components/Datagrid/utils/validation";

const columns = [
  {
    field: "description",
    headerName: "Description",
    type: "text",
    editable: true,
    minWidth: 150,
  },
  {
    field: "type",
    headerName: "Type",
    type: "singleSelect",
    editable: true,
    valueOptions: [
      { text: "Percent", value: "percent" },
      { text: "Monetary", value: "monetary" },
    ],
    minWidth: 120,
    preProcessEditCellProps: params => {
      const {
        props,
        props: { value },
      } = params;

      return {
        ...props,
        error: !value,
      };
    },
    getOptionLabel: ({ text }) => text,
    getOptionValue: ({ value }) => value,
  },
  {
    field: "value",
    headerName: "Value",
    type: "text",
    editable: true,
    minWidth: 100,
    valueGetter: value => {
      return value / 100;
    },

    valueFormatter: (value, row) => {
      const numericValue = value; // Convert from pence/basis points to pounds/percentage

      // Add the appropriate symbol based on type
      if (row.type === "percent") {
        return `${numericValue}%`;
      }
      return `£${numericValue}`;
    },
    preProcessEditCellProps: params => {
      const { props, otherFieldsProps } = params;
      const { value } = props;

      // Return early if no value provided
      if (!value) {
        return { ...props, error: true };
      }

      const stringValue = value.toString();

      // Validation checks
      const validationErrors = [
        // Basic number validation
        hasNumberValidationError(value),
        // Check for max 2 decimal places
        stringValue.includes(".") && stringValue.split(".")[1]?.length > 2,
        // Must be positive
        value <= 0,
        // Percentage must be between 0 and 100
        otherFieldsProps.type.value === "percent" &&
          (parseFloat(stringValue) > 99.99 || parseFloat(stringValue) < 0),
      ];

      return {
        ...props,
        error: validationErrors.some(error => error),
      };
    },
  },
  {
    field: "allProducts",
    headerName: "Apply to All Products",
    type: "boolean",
    editable: true,
    minWidth: 200,
  },
];

export default columns;
