import React from "react";
import PageWithHeading from "../../components/PageWithHeading";
import ServiceChargesBulkEditor from "./Components/ServiceChargesBulkEditor";

const ServiceChargesList = () => (
  <PageWithHeading title="Service Charges">
    <ServiceChargesBulkEditor />
  </PageWithHeading>
);

export default ServiceChargesList;
