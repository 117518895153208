import React from "react";
import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import ErrorBoundary from "./ErrorBoundary";
import Page from "./Page";

/**
 * A reusable page component that provides a consistent layout with a heading and content area.
 *
 * @param {Object} props - Component props
 * @param {string} props.title - The page title to display
 * @param {React.ReactNode} props.children - The main content to render in the content area
 * @returns {React.ReactElement} The rendered page component
 */
const PageWithHeading = ({ title, children }) => {
  return (
    <ErrorBoundary>
      <Page title={title}>
        <Container maxWidth={false}>
          <Grid container rowSpacing={3}>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              alignItems="center"
              mt={3}
            >
              <Grid item>
                <Typography variant="h2" data-testid="main-header">
                  {title}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ width: "0px" }}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </ErrorBoundary>
  );
};

PageWithHeading.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageWithHeading;
