import { v4 } from "uuid";

const generateNewRow = () => {
  return {
    id: v4(),
    name: "",
    code: "",
    type: "CLIENT", // Default type
    POSPaymentTypeId: "",
    defaultBookingTypeId: "",
    defaultDiscountCode: "",
    orderMessageTimeoutMinutes: null,
    createdRow: true, // Flag to identify newly created rows
  };
};

export default generateNewRow;
