import React, { useState } from "react";

import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { DataGrid } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";
import CustomToolbar from "../../../components/Datagrid/CustomToolbar";
import columns from "../utils/columns";
import generateNewRow from "../utils/generateNewRow";
import {
  ADD_SERVICE_CHARGE,
  GET_SERVICE_CHARGES,
  UPDATE_SERVICE_CHARGE,
} from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  height: "70vh",
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const ServiceChargesBulkEditor = ({ appStore }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState([]);
  const [createdRow, setCreatedRow] = useState(false);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "bulk-service-charges-error-snackbar",
      },
    });
  };

  const { loading: loadingServiceCharges } = useQuery(
    gql(GET_SERVICE_CHARGES()),
    {
      fetchPolicy: "cache-and-network",
      onCompleted: data => {
        const serviceCharges = data?.serviceChargesWithCount?.serviceCharges;

        if (serviceCharges) {
          setTableData(serviceCharges);
        }

        appStore.setLoading(false);
      },
      onError: error => {
        appStore.setLoading(false);
        errorSnackbar(error.message);
      },
    },
  );

  const [addServiceCharge] = useMutation(gql(ADD_SERVICE_CHARGE()), {
    onCompleted: () => {
      enqueueSnackbar("Your new entry has been created", {
        SnackbarProps: {
          "data-testid": "bulk-service-charges-added-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SERVICE_CHARGES()), "ServiceChargesWithCount"],
  });

  const [updateServiceCharge] = useMutation(gql(UPDATE_SERVICE_CHARGE()), {
    onCompleted: () => {
      enqueueSnackbar("Your changes have been saved", {
        SnackbarProps: {
          "data-testid": "bulk-service-charges-saved-snackbar",
        },
        variant: "success",
      });
    },
    refetchQueries: [gql(GET_SERVICE_CHARGES()), "ServiceChargesWithCount"],
  });

  const addRow = () => {
    const newRow = generateNewRow();
    const newTableData = [...tableData];
    newTableData.unshift(newRow);
    setTableData(newTableData);
    setCreatedRow(true);
  };

  const deleteRow = () => {
    const newTableData = tableData.filter(row => row.createdRow === undefined);
    setTableData(newTableData);
    setCreatedRow(false);
  };

  const handleSubmitRow = async (updatedRow, originalRow) => {
    // Convert value to number and ensure it's in the correct format (pence/basis points)
    const convertedValue = updatedRow.value
      ? Math.round(parseFloat(updatedRow.value) * 100)
      : 0;

    // For new rows, prepare all fields
    if (updatedRow.createdRow) {
      const input = {
        description: updatedRow.description,
        type: updatedRow.type,
        allProducts: updatedRow.allProducts,
        value: convertedValue,
      };

      const submitData = { variables: { input } };

      // Return the formatted row after successful mutation
      return addServiceCharge(submitData).then(() => {
        setCreatedRow(false);
        return {
          ...updatedRow,
          value: convertedValue,
        };
      });
    }

    // For existing rows, check what has changed
    const changes = {};
    const managableFields = ["description", "type", "allProducts", "value"];

    // Handle all fields including value
    managableFields.forEach(field => {
      if (field === "value") {
        const originalValueDisplay = parseFloat(originalRow[field]) / 100;
        const updatedValueDisplay = parseFloat(updatedRow[field] || 0);

        if (originalValueDisplay !== updatedValueDisplay) {
          changes[field] = convertedValue;
        }
      } else if (originalRow[field] !== updatedRow[field]) {
        changes[field] = updatedRow[field];
      }
    });

    // If no changes, return the row as is
    if (!Object.keys(changes).length) {
      return {
        ...updatedRow,
        value: convertedValue,
      };
    }

    // Add ID for update mutation
    changes.id = updatedRow.id;

    // Perform update mutation
    return updateServiceCharge({ variables: { input: changes } }).then(() => {
      return {
        ...updatedRow,
        value: convertedValue,
      };
    });
  };

  return (
    <StyledBox>
      <DataGrid
        columns={columns}
        editMode="row"
        loading={loadingServiceCharges}
        rows={tableData}
        slots={{
          row: CustomGridRow,
          toolbar: () => (
            <CustomToolbar
              addAction={addRow}
              deleteAction={deleteRow}
              isRowCreated={createdRow}
            />
          ),
        }}
        processRowUpdate={handleSubmitRow}
        onProcessRowUpdateError={errorSnackbar}
      />
    </StyledBox>
  );
};

ServiceChargesBulkEditor.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject("appStore")(observer(ServiceChargesBulkEditor));
